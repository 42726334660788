import React from "react";
import { useDispatch } from "react-redux";
import useFetch from "commons/hook/fetchDataHook";
import { setProfileAction, logoutAction } from "redux/actions";
import { endpoints } from "constants/endpoints";
import { useNavigate, useLocation } from "react-router-dom";

const WHITE_LIST_PAGE = ["/login", "/register"];
const LOGIN_PAGE = "/login";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  // TODO: OPEN WHEN HAVE API
  // const reloadProfile = () => {
  //   upParams("");
  //   upParams({ submit: 1 });
  // };

  // const [data, upEndpoints, upParams] = useFetch(
  //   endpoints.get_profile,
  //   {},
  //   "get",
  //   (result, params) => {
  //     dispatch(
  //       setProfileAction({ ...result?.data, reloadProfile: reloadProfile })
  //     );
  //   },
  //   (result, params) => {
  //     dispatch(logoutAction());
  //     if (!WHITE_LIST_PAGE.includes(location.pathname)) {
  //       navigate(LOGIN_PAGE);
  //     }
  //     dispatch(setProfileAction({ reloadProfile: reloadProfile }));
  //   }
  // );

  return <></>;
};

export default Header;
