import LoginPageLayout from "pages/login";
import RegisterPageLayout from "pages/login/register";

const routeSchema = [
  {
    path: "/login",
    exact: true,
    component: <LoginPageLayout />,
  },
  {
    path: "/register",
    exact: true,
    component: <RegisterPageLayout />,
  },
];

export default routeSchema;
