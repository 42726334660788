import React from "react";
import PreLoginLayout from "components/PageLayout/PreloginLayout";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import { t1, t3 } from "translate";
import FormCreator from "commons/final-form/core/FormCreator";
import loginLayout from "schema-form/layout/loginLayout";
import loginSchema from "schema-form/schema/loginSchema";
import loginUI from "schema-form/ui/loginUI";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { loginAction, setProfileAction } from "redux/actions";
import { connect } from "react-redux";
import Divider from "antd/lib/divider";

const LoginLayout = ({ dispatch }) => {
  const reloadProfile = () => {
    upParamsprofile("");
    upParamsprofile({ submit: 1 });
  };

  const [
    dataprofile,
    upEndpointsprofile,
    upParamsprofile,
    updateMethod,
    updateAuth,
  ] = useFetch(
    endpoints.get_profile,
    {},
    "get",
    (result, params) => {
      dispatch(
        setProfileAction({ ...result?.data, reloadProfile: reloadProfile })
      );
    },
    (result, params) => {}
  );

  const [data, UpEnpoints, setLoginParams] = useFetch(
    endpoints.login,
    "",
    "post",
    (result, params) => {
      dispatch(loginAction(result?.data?.token));
      updateAuth(result?.data?.token);
      reloadProfile();
      message.success("Login successful");
    },
    (result, params) => {
      if (!result?.success) {
        message.error(result?.message || t1("login_failed"));
      } else {
        message.error(t1("login_failed"));
      }
    }
  );

  return (
    <PreLoginLayout>
      <Row>
        <Col span={24} md={{ span: 6, offset: 9 }} xs={{ span: 22, offset: 1 }}>
          <Card
            title={null}
            bordered={true}
            style={{ width: "100%", marginTop: "130px" }}
          >
            <h3 className="text-center mb-2 mt-3">{t3("login_panel")}</h3>
            <FormCreator
              debugMode={false}
              schema={loginSchema}
              ui={loginUI}
              layout={loginLayout}
              onSubmit={(value) => {
                setLoginParams(value);
              }}
              SubmitButton={(form) => {
                return (
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={(e) => form.submit()}
                  >
                    {t1("click_to_login")}
                  </Button>
                );
              }}
            />
            <Divider />
          </Card>
        </Col>
      </Row>
    </PreLoginLayout>
  );
};

export default connect()(LoginLayout);
