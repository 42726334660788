import React from "react";
import {
  ProfileOutlined,
  HomeOutlined,
  DashboardOutlined,
  GoldOutlined,
} from "@ant-design/icons";
import { t1 } from "translate";
import { MENU_LINK_DASHBOARD } from "constants/menu-link";

const MENU_LIST = [
  {
    key: 1,
    icon: <HomeOutlined />,
    label: "Home",
    link: "/",
  },
  {
    key: 3,
    icon: <GoldOutlined />,
    label: "Danh sách",
    link: "/setting",
  },
  // {
  //   key: 2,
  //   icon: <ProfileOutlined />,
  //   label: "Kịch Bản",
  //   link: "/content",
  //   children: [
  //     {
  //       key: 11,
  //       label: "Kịch bản tự động",
  //       link: "/content",
  //     },
  //     {
  //       key: 12,
  //       label: "Kịch bản Response",
  //       link: "/content/response",
  //     },
  //   ],
  // },
];

export default MENU_LIST;
