import React from "react";
import PageLayout from "components/PageLayout";
import Button from "antd/lib/button";
import { t1 } from "translate";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardLayout = ({ accountInfo, ...pages }) => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <h1>Introduce</h1>
      <div className="flex flex-row mt-8">
        <iframe
          width="65%"
          height="450"
          src="https://www.youtube.com/embed/5yjvjnwMDu8?si=6Hn4-6Vvvc7PoZNf"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <div className="ml-12 w-1/3 mt-36">
          <h2>Storm Bot</h2>
          <p>
            Giúp người sử dụng nâng cao năng suất lên nhiều lần. Với
            mức độ chính xác luôn được cải thiện dần trong mỗi lần sử dụng,
            storm bot sẽ ngày càng hỗ trợ người sử dụng hiệu quả hơn và đạt được
            năng suất cao nhất trong khả năng.
          </p>
          <Button
            type="primary"
            onClick={() => {
              navigate("setting");
            }}
          >
            View List
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state, props) => {
  return {
    accountInfo: state?.profile?.account,
  };
};

export default connect(mapStateToProps)(DashboardLayout);
