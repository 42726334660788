import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from "translate";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "antd";
import autoSchema from "schema-form/schema/contentAutoSchema";
import contentAutoUI from "schema-form/ui/contentAutoUI";
import contentAutoLayout from "schema-form/layout/contentAutoLayout";
import FormCreator from "commons/final-form/core/FormCreator";
import responseSchema from "schema-form/schema/contentResponseSchema";
import contentResponseUI from "schema-form/ui/contentResponse";
import contentResponseLayout from "schema-form/layout/contentResponseLayout";


const CreateEditResponseLayout = ({ accountInfo, ...pages }) => {
  return (
    <PageLayout>
      <h1>Kịch bản Response</h1>
      <Row>
        <Col span={24} xs={{ span: 22, offset: 1 }}>
          <Card
            bordered={true}
            style={{
              width: "100%",
              marginTop: "30px",
            }}
          >
            <FormCreator
              debugMode={false}
              schema={responseSchema}
              ui={contentResponseUI}
              layout={contentResponseLayout}
              onSubmit={(value) => {
                }}
              SubmitButton={(form) => {
                return (
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={(e) => form.submit()}
                  >
                    Submit
                  </Button>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
      
    </PageLayout>
  );
};

const mapStateToProps = (state, props) => {
  return {
    accountInfo: state?.profile?.account,
  };
};

export default connect(mapStateToProps)(CreateEditResponseLayout);
