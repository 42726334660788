import { t1 } from "translate";
import { composeValidators, required, emailValidator } from "validations/index";

const loginSchema = {
  email: {
    type: "text",
    label: t1("email"),
    validate: composeValidators(required, emailValidator),
    errorText: {
      required: t1("email_is_require"),
      emailInvalid: t1("email_is_not_valid"),
    },
  },
  password: {
    type: "password",
    label: t1("password"),
    validate: composeValidators(required),
    errorText: {
      required: t1("password_is_require"),
    },
  },
};

export default loginSchema;
