import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Row, Col } from "antd";
import { useState, useEffect } from "react";
import AuthWrapper from "components/AuthWrapper";
import { connect } from "react-redux";
import { logoutAction } from "redux/actions";
import MENU_LIST from "constants/menus";
import { t1 } from "translate";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "antd/lib/avatar";
import Typography from "antd/lib/typography";
import Divider from "antd/lib/divider";
import Breadcrumb from "antd/lib/breadcrumb";
import styles from "./styles.module.scss";

const { Header, Content, Sider } = Layout;
const { Title, Text } = Typography;

const addLogoutMenu = (menuList = [], dispatch) => {
  let output = menuList;

  // if (!output.find((e) => e.key == 9)) {
  //   output.push({
  //     key: 9,
  //     icon: <LogoutOutlined />,
  //     label: t1("logout"),
  //     onClick: (e) => dispatch(logoutAction()),
  //   });
  // }

  return output;
};

const convertMenuToLink = (menuList = [], navigate, location) => {
  let output = [];

  menuList.map((menuData) => {
    if (menuData?.children) {
      output.push({
        ...menuData,
        children: convertMenuToLink(menuData.children, navigate, location),
      });

      const checkActiveTab = (menuData?.children || [])?.findIndex(
        (tab) => tab?.link === location.pathname
      );
      if (checkActiveTab !== -1) {
        menuData.style = { background: "#2d3948", borderRadius: 0 };
        menuData.children = menuData?.children?.map((tab, tabIndex) => ({
          ...tab,
          style: {
            background: tabIndex === checkActiveTab ? "#383d43" : "none",
          },
        }));
      } else {
        menuData.style = { background: "none" };
      }
    } else {
      output.push({
        ...menuData,
        onClick: (e) => {
          if (location.pathname != menuData.link) {
            navigate(menuData.link);
          }
        },
      });
    }
  });

  return output;
};

const PageLayout = ({
  children,
  dispatch,
  breadcrumList = [],
  profile,
  auth,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (typeof profile?.reloadProfile === "function") {
      profile.reloadProfile();
    }
  }, [location.pathname]);

  return (
    // TODO: OPEN WHEN HAVE API
    // <AuthWrapper>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Row>
            <Col
              span={24}
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "64px",
                backgroundColor: "#fff",
                padding: collapsed ? "1px 7px 1px 7px" : "15px 10px",
              }}
            >
              <img src="/amela_logo.png" alt="logo" style={{ width: "35px" }} />
              {!collapsed && (
                <h4 style={{ display: "inline", margin: "0px 8px" }}>STORM</h4>
              )}
            </Col>
            <Col span={24} style={{ maxHeight: "90vh", overflow: "auto" }}>
              <Row style={{ padding: "15px 0px 5px", color: "white" }}>
                <div className={styles.detail}>
                  <Avatar
                    className={styles.avatar}
                    shape="circle"
                    size={45}
                    icon={<img src="/avatarAmela.png" alt=""/>}
                  />
                  {!collapsed && (
                    <div>
                      <Title
                        level={4}
                        className={styles.userName}
                        ellipsis={true}
                        style={{}}
                      >
                        {profile?.profile?.username}
                      </Title>
                    </div>
                  )}
                </div>
                <Divider
                  className="mt-3 mb-1"
                  style={{ borderColor: "rgba(255,255,255,0.4)" }}
                />
              </Row>
              <Menu
                theme="dark"
                defaultSelectedKeys={[]}
                mode="inline"
                items={addLogoutMenu(
                  convertMenuToLink(MENU_LIST, navigate, location),
                  dispatch
                )}
              />
            </Col>
          </Row>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          ></Header>
          <Content
            style={{
              padding: "0 16px",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "28px",
            }}
            className="pt-3"
          >
            <Breadcrumb
              items={[
                ...breadcrumList,
              ].map((breadcrumbInfo) => {
                return {
                  title: breadcrumbInfo?.link ? (
                    <a href={breadcrumbInfo?.link}>{breadcrumbInfo?.title}</a>
                  ) : (
                    breadcrumbInfo?.title
                  ),
                };
              })}
            />
            {children}
          </Content>
        </Layout>
      </Layout>
    // </AuthWrapper>
  );
};

const mapStateToProps = (state, props) => {
  return {
    profile: state?.profile,
    auth: state?.auth?.token,
  };
};

export default connect(mapStateToProps)(PageLayout);
