import { t1 } from "translate";
import { required } from "commons/final-form/utils";
import { composeValidators } from "commons/final-form/utils";

const autoSchema = {
  name_flow: {
    type: "text",
    label: 'Tên flow',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  link_connect: {
    type: "text",
    label: 'Link liên kết',
    validate: composeValidators(required),
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  time_setting: {
    type: "timepicker",
    label: 'Thời gian',
    className: 'w-full',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  person_receive_noti: {
    type: "text",
    label: 'Người nhận thông báo',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  message_noti_new_ticket: {
    type: "textarea",
    label: 'Message nhận thông báo mới',
    autoSize: {
      minRows: 3,
      maxRows: 3,
    },
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  person_confirm: {
    type: "text",
    label: 'Người xác nhận',
    validate: composeValidators(required),
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
};

export default autoSchema;
