import loginRouteSchema from "pages/login/routes";
import dashboard from "pages/dashboard/routes";
import createAuto from "pages/createEditAuto/routes";
import createResponse from "pages/createEditResponse/routes";
import listContent from 'pages/List/routes';


const routeSchema = [
    ...loginRouteSchema,
    ...dashboard,
    ...createAuto,
    ...createResponse,
    ...listContent
];

export default routeSchema;
