import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const contentResponseLayout = (formComponent) => {
  return (
    <Row gutter={[20, 16]}>
      <Col span={12}>{formComponent?.name_flow}</Col>
      <Col span={12}>{formComponent?.syntax_start}</Col>
      <Col span={12}>{formComponent?.message_success}</Col>
      <Col span={12}>{formComponent?.message_waiting}</Col>
      <Col span={12}>{formComponent?.message_fail}</Col>
      <Col span={12}>{formComponent?.syntax_end}</Col>
      <Col span={4}>{formComponent?.SubmitButton}</Col>
    </Row>
  );
};

export default contentResponseLayout;
