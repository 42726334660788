import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { t1 } from "translate";

const registerLayout = (formComponent) => {
  return (
    <Row gutter={[20, 16]}>
      <Col span={24}>{formComponent?.user_name}</Col>
      <Col span={24}>{formComponent?.email}</Col>
      <Col span={24}>{formComponent?.password}</Col>
      <Col span={24}>{formComponent?.confirm_password}</Col>
      <Col span={24}>{formComponent?.SubmitButton}</Col>
      <Col span={24}>
        <Button
          style={{ width: "100%" }}
          onClick={(e) => (window.location = "/login")}
        >
          {t1("click_to_login")}
        </Button>
      </Col>
    </Row>
  );
};

export default registerLayout;
