import CreatePageLayout from "pages/createEditResponse";

const routeSchema = [
  {
    path: "/content/response",
    exact: true,
    component: <CreatePageLayout />,
  },
];

export default routeSchema;
