import ListPageLayout from "pages/List";

const routeSchema = [
  {
    path: "/setting",
    exact: true,
    component: <ListPageLayout />,
  },
];

export default routeSchema;
