import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import Button from "antd/lib/button";
import { t1 } from "translate";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Col, Switch } from "antd";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";

const ListLayout = ({ accountInfo, ...pages }) => {
  const navigate = useNavigate();
  const listFake = [
    {
      id: 1,
      name: "Kịch Bản Dịch",
      describe:
        "Người dùng nhập message trong đó có từ 'Dịch', \n Bot sẽ tự động trả lời và yêu cầu người dùng gửi đường dẫn link, file hoặc văn bản cần dịch, kèm theo ngôn ngữ đích. Tiếp đó, khi người dùng nhập đúng cú pháp theo yêu cầu, Bot sẽ nhắn về cho người dùng 1 message yêu cầu đợi kết quả. Sau khi có kết quả dịch, Bot sẽ trả cho người dùng file, link hoặc văn bản đã dịch. Người dùng nhắn 'OK' để xác nhận và 'END' để kết thúc.",
      updated_at: "28-11-2023",
      status: true,
    },
    {
      id: 2,
      name: "Kịch Bản Cập Nhật Backlog",
      describe: "",
      status: false,
      updated_at: "28-11-2023",
    },
  ];
  const [datas, setData] = useState([]);

  async function fetchList() {
    try {
      const response = await fetch(
        "https://storm-api.test4.amelacorp.com/api/flow/list",
        {
          method: "GET",
        }
      );
      const result = await response.json();
      setData(result.data || []);
    } catch (error) {}
  }

  useEffect(() => {
    fetchList();
  }, []);
  const [data, useEndPoint, setParams] = useFetch(
    `flow/1/update-status`,
    "",
    "post",
    (result, params) => {},
    (result, params) => {}
  );

  const [dat, , setParam] = useFetch(
    `flow/4/update-status`,
    "",
    "post",
    (result, param) => {},
    (result, param) => {}
  );
  const onChange = (checked) => {
    setParams({ status: checked ? 1 : 0 });
  };
  const onChange4 = (checked) => {
    setParam({ status: checked ? 1 : 0 });
  };
  return (
    <PageLayout>
      <h1>Kịch Bản</h1>
      <div className="flex flex-row">
        <Card
          className="mr-4"
          bordered={true}
          style={{
            width: "30%",
            marginTop: "30px",
          }}
        >
          <Col>
            <h2>{datas[0]?.name}</h2>
          </Col>
          <Col>
            <p className="h-48">
              Người dùng nhập message 'dịch file cho tôi' hoặc 'dịch', Bot sẽ tự
              động trả lời và yêu cầu người dùng gửi đường dẫn link, file hoặc
              văn bản cần dịch, kèm theo ngôn ngữ đích. Tiếp đó, khi người dùng
              nhập đúng cú pháp theo yêu cầu, Bot sẽ nhắn về cho người dùng 1
              message yêu cầu đợi kết quả. Sau khi có kết quả dịch, Bot sẽ trả
              cho người dùng file, link hoặc văn bản đã dịch.
            </p>
          </Col>
          <Col className="">
            <p className="bold">
              Ngày Tạo: {dayjs(datas[0]?.updated_at).format("DD/MM/YYYY")}
            </p>
          </Col>
          <Col className="flex flex-row items-center">
            <Switch
              defaultChecked
              style={{ margin: 16 }}
              onChange={onChange4}
            />
          </Col>
        </Card>
        <Card
          className="mr-4"
          bordered={true}
          style={{
            width: "30%",
            marginTop: "30px",
          }}
        >
          <Col>
            <h2>{datas[1]?.name}</h2>
          </Col>
          <Col>
            <p className="h-48">
              Người dùng sẽ nhận được noti khi có ticket mới trên backlog. Những
              ticket tiếng Nhật sẽ được dịch, cập nhật bản dịch, assign và
              mention người chịu trách nhiệm với ticket đó trên backlog.
            </p>
          </Col>
          <Col className="">
            <p className="bold">
              Ngày Tạo: {dayjs(datas[1]?.updated_at).format("DD/MM/YYYY")}
            </p>
          </Col>
          <Col className="flex flex-row items-center">
            <Switch style={{ margin: 16 }} defaultChecked onChange={onChange} />
          </Col>
        </Card>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state, props) => {
  return {
    accountInfo: state?.profile?.account,
  };
};

export default connect(mapStateToProps)(ListLayout);
