import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const contentAutoLayout = (formComponent) => {
  return (
    <Row gutter={[20, 16]}>
      <Col span={12}>{formComponent?.name_flow}</Col>
      <Col span={12}>{formComponent?.link_connect}</Col>
      <Col span={12}>{formComponent?.time_setting}</Col>
      <Col span={12}>{formComponent?.person_receive_noti}</Col>
      <Col span={12}>{formComponent?.message_noti_new_ticket}</Col>
      <Col span={12}>{formComponent?.person_confirm}</Col>
      <Col span={4}>{formComponent?.SubmitButton}</Col>
    </Row>
  );
};

export default contentAutoLayout;
