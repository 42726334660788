import { Layout, Menu, theme } from "antd";
import AuthWrapper from "components/AuthWrapper";

const { Header, Content } = Layout;

const sectionStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: "url(/background_login.jpg)",
};

const PreLoginLayout = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <AuthWrapper>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Layout>
          <Content style={sectionStyle}>{children}</Content>
        </Layout>
      </Layout>
    </AuthWrapper>
  );
};

export default PreLoginLayout;
