import React, { useEffect, useState } from 'react';
import Requester from 'commons/network/Request';
import lGet from 'lodash/get';
import mappingFakeData from 'fake-data/mapping';

const DataFetchHook = (initUrl, initParams, initMethod, callBack, callBackFailed, callBackProgressChange) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState({ count: 0, total: 0 });
  const [url, setUrl] = useState(initUrl);
  const [params, setParams] = useState(initParams);
  const [method, setMethod] = useState(initMethod);
  const [auth, setAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [serverTs, setServerTs] = useState(Math.floor(Date.now() / 1000));
  const [currentAuth, setCurrentAuth] = useState(false);

  const options = {
    onUploadProgress: (progressEvent) => {
      if (callBackProgressChange) {
        callBackProgressChange(progressEvent);
      }
    },
  };

  useEffect(() => {
    const fetchData = async (url, params) => {
      setIsError(false);
      setIsLoading(true);
      try {
        var result = [];
        switch (method.trim().toLowerCase()) {
          case 'get':
            result = await Requester.get(url, params, options);
            break;
          case 'post':
            result = await Requester.post(url, params, options);
            break;
          case 'put':
            result = await Requester.put(url, params, options);
            break;
          case 'delete':
            result = await Requester.delete(url, params, options);
            break;
        }
        if (result.success) {
          if (result.result !== undefined) {
            setData(result.result);
          } else if (result.data !== undefined) {
            setData(result.data);
          } else {
            setData([]);
          }
          if (result.objects !== undefined) {
            setPage(result.objects);
          }
          if (lGet(result, 'server_ts')) {
            setServerTs(lGet(result, 'server_ts'));
          }
          if (callBack !== undefined) {
            if (result['missing']) {
              callBack(result.result, params, result['missing'], lGet(result, 'message'), lGet(result, 'total_line'));
            } else {
              callBack(
                result.result ? result.result : result,
                params,
                lGet(result, 'message'),
                lGet(result, 'total_line')
              );
            }
          }
        } else {
          setData([]);
          setIsError(true);
          if (typeof callBackFailed == 'function') {
            callBackFailed(result.result ? result.result : result, params, result.message, lGet(result, 'objects', {}));
          }
        }
      } catch (error) {
        setIsError(true);
        //message.error(t1('fetch_data_error'));
      }
      setIsLoading(false);
    };

    const fetchDataFake = (url,params) => {
      setIsError(false);
      setIsLoading(true);
      //get to list of api and try to get the most match with params and output data
      let result = mappingFakeData(url,params);

      if (result.success) {
        if (result.result !== undefined) {
          setData(result.result);
        } else {
          setData([]);
        }
        
        if (result.objects !== undefined) {
          setPage(result.objects);
        }
        
        if (lGet(result, 'server_ts')) {
          setServerTs(lGet(result, 'server_ts'));
        }
        
        if (callBack !== undefined) {
          callBack(result, params);
        }
      } else {
        setData([]);
        setIsError(true);
        if (typeof callBackFailed == 'function') {
          callBackFailed(result.result ? result.result : result, params, result.message, lGet(result, 'objects', {}));
        }
      }
      setIsLoading(false);
    }

    if (window.TEST_MODE){
      //testmode will automatically return data from fake data with a delay of 1 second (to act like an api call)
      if (url !== '' && params !==''){
        fetchDataFake(url, params);
      }
    }else{
      if (url !== '' && params !== '') {
        fetchData(url, params);
      } 
    }
  }, [url, params, method]);

  useEffect(() => {
    if (auth && auth != currentAuth){
      setCurrentAuth = auth;
      Requester.setAuthToken(auth);
    }
  },[auth,currentAuth]);

  return [{ data, isLoading, isError, page, serverTs, setData }, setUrl, setParams, setMethod, setAuth];
};

export default DataFetchHook;
