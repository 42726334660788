import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { t1 } from "translate";

const loginLayout = (formComponent) => {
  return (
    <Row gutter={[20, 8]}>
      <Col span={24} className="mb-1">
        {formComponent?.email}
      </Col>
      <Col span={24} className="mb-1">
        {formComponent?.password}
      </Col>
      <Col span={24} className="mb-1">
        {formComponent?.SubmitButton}
      </Col>
      <Col span={24}>
        <Button
          style={{ width: "100%" }}
          onClick={(e) => (window.location = "/register")}
        >
          {t1("click_to_register")}
        </Button>
      </Col>
    </Row>
  );
};

export default loginLayout;
