import CreatePageLayout from "pages/createEditAuto";

const routeSchema = [
  {
    path: "/content",
    exact: true,
    component: <CreatePageLayout />,
  },
];

export default routeSchema;
