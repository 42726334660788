import { t1 } from "translate";
import { required } from "commons/final-form/utils";
import { composeValidators, emailValidator } from "commons/final-form/utils";

const responseSchema = {
  name_flow: {
    type: "text",
    label: 'Tên flow',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  syntax_start: {
    type: "text",
    label: 'Cú pháp bắt đầu',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  syntax_confirm: {
    type: "text",
    label: 'Cú pháp xác nhận',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  message_success: {
    type: "textarea",
    label: 'Message success',
    autoSize: {
      minRows: 3,
      maxRows: 3,
    },
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  message_waiting:{
    type: "textarea",
    label: 'Message waiting',
    autoSize: {
      minRows: 3,
      maxRows: 3,
    },
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  message_fail: {
    type: 'textarea',
    label: 'Message fail',
    autoSize: {
      minRows: 3,
      maxRows: 3,
    },
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
  syntax_end: {
    type: "text",
    label: 'Cú pháp kết thúc',
    validate: required,
    errorText: {
      required: t1("this_field_is_require"),
    },
  },
};

export default responseSchema;
